* {
    box-sizing: border-box;
}

body {
    background: #ffffff;
    font-size: 18px;
    color: #001432;
    font-family: AvenirNextLTPro-Medium, AvenirNextCyrMedium, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    flex-direction: column;
    flex: 1;
}
