/**
 * @license
 * MyFonts Webfont Build ID 3959936, 2020-10-13T06:51:27-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AvenirNextLTPro-Demi by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-demi/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 *
 * Webfont: AvenirNextLTPro-Medium by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-medium/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 *
 * Webfont: AvenirLTPro-Black by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-95-black/
 * Copyright: Copyright &amp;#x00A9; 2014 Monotype GmbH. All rights reserved.
 *
 *
 *
 * © 2020 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3c6c80");

@font-face {
    font-family: 'AvenirNextLTPro-Medium';
    src: url('font/AvenirNextLTPro-Medium/font.woff2') format('woff2'), url('font/AvenirNextLTPro-Medium/font.woff') format('woff');
}
@font-face {
    font-family: 'AvenirNextLTPro-Demi';
    src: url('font/AvenirNextLTPro-Demi/font.woff2') format('woff2'), url('font/AvenirNextLTPro-Demi/font.woff') format('woff');
}
@font-face {
    font-family: 'AvenirLTPro-Black';
    src: url('font/AvenirLTPro-Black/font.woff2') format('woff2'), url('font/AvenirLTPro-Black/font.woff') format('woff');
}
@font-face {
    font-family: 'AvenirNextCyrMedium';
    src: url('font/AvenirNextCyrMedium/font.woff2') format('woff2'), url('font/AvenirNextCyrMedium/font.woff') format('woff');
}
@font-face {
    font-family: 'AvenirNextCyrBold';
    src: url('font/AvenirNextCyrBold/font.woff2') format('woff2'), url('font/AvenirNextCyrBold/font.woff') format('woff');
}
@font-face {
    font-family: 'AvenirNextCyrDemi';
    src: url('font/AvenirNextCyrDemi/font.woff2') format('woff2'), url('font/AvenirNextCyrDemi/font.woff') format('woff');
}
