@import url(//hello.myfonts.net/count/3c6c80);
/**
 * @license
 * MyFonts Webfont Build ID 3959936, 2020-10-13T06:51:27-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AvenirNextLTPro-Demi by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-demi/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 *
 * Webfont: AvenirNextLTPro-Medium by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-medium/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 *
 * Webfont: AvenirLTPro-Black by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-95-black/
 * Copyright: Copyright &amp;#x00A9; 2014 Monotype GmbH. All rights reserved.
 *
 *
 *
 * © 2020 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */

@font-face {
    font-family: 'AvenirNextLTPro-Medium';
    src: url(/static/media/font.ef1084ec.woff2) format('woff2'), url(/static/media/font.0dba095f.woff) format('woff');
}
@font-face {
    font-family: 'AvenirNextLTPro-Demi';
    src: url(/static/media/font.81e72216.woff2) format('woff2'), url(/static/media/font.5d944b8e.woff) format('woff');
}
@font-face {
    font-family: 'AvenirLTPro-Black';
    src: url(/static/media/font.5b7684a7.woff2) format('woff2'), url(/static/media/font.07569c72.woff) format('woff');
}
@font-face {
    font-family: 'AvenirNextCyrMedium';
    src: url(/static/media/font.a51b0ab4.woff2) format('woff2'), url(/static/media/font.b98881e5.woff) format('woff');
}
@font-face {
    font-family: 'AvenirNextCyrBold';
    src: url(/static/media/font.993e4885.woff2) format('woff2'), url(/static/media/font.9794fe32.woff) format('woff');
}
@font-face {
    font-family: 'AvenirNextCyrDemi';
    src: url(/static/media/font.5d7072de.woff2) format('woff2'), url(/static/media/font.b1971084.woff) format('woff');
}

* {
    box-sizing: border-box;
}

body {
    background: #ffffff;
    font-size: 18px;
    color: #001432;
    font-family: AvenirNextLTPro-Medium, AvenirNextCyrMedium, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

